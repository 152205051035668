// import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ListAltIcon from '@material-ui/icons/ListAlt'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SpeedIcon from '@material-ui/icons/Speed'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
// import GroupIcon from '@material-ui/icons/Group'
import StorefrontIcon from '@material-ui/icons/Storefront'
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi'
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import SettingsIcon from '@material-ui/icons/Settings'
import WorkIcon from '@material-ui/icons/Work'
import BusinessIcon from '@material-ui/icons/Business'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import RestoreIcon from '@material-ui/icons/Restore'
import HomeIcon from '@material-ui/icons/Home'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
// import PeopleIcon from '@material-ui/icons/People'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
// import CodeIcon from '@material-ui/icons/Code'
import ShareIcon from '@material-ui/icons/Share'
import ExposureIcon from '@material-ui/icons/Exposure'

import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import StoreIcon from '@material-ui/icons/Store'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import HistoryIcon from '@material-ui/icons/History'
// import BusinessIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import { CardMembership, Dashboard, HourglassEmpty, Public } from '@material-ui/icons'
import PublishIcon from '@material-ui/icons/Publish'
import PeopleIcon from '@material-ui/icons/People'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
/* Configurazione degli oggetti per le voci del menù laterale che verranno importati e mostrati nel componente NavBar */
export const useConfig = () => {
  const { t } = useTranslation('navBar')

  const superAdmin = [
    {
      subheader: t('superAdmin.subheader'),
      items: [
        {
          title: t('superAdmin.userSection.title'),
          icon: HowToRegIcon,
          items: [{
            title: t('superAdmin.userSection.registered'),
            href: '/users'
          },
          {
            title: t('superAdmin.userSection.invited'),
            href: '/invitedusers'
          },
          {
            title: t('superAdmin.userSection.activations'),
            href: '/activations'
          }]
        },
        {
          title: t('superAdmin.companies'),
          href: '/companies',
          icon: BusinessIcon
        },
        {
          title: t('superAdmin.marketplaces'),
          href: '/manageMarketplaces',
          icon: StorefrontIcon
        },
        {
          title: t('superAdmin.registry'),
          href: '/onboarding/registry',
          icon: PlaylistAddIcon
        },
        {
          title: t('superAdmin.files'),
          href: '/onboarding/files',
          icon: CloudUploadIcon
        },
        {
          title: t('superAdmin.simulations'),
          href: '/auctions/simulations',
          icon: ExposureIcon
        },
        {
          title: t('superAdmin.auctions'),
          href: '/auctions',
          icon: SpeedIcon
        },
        {
          title: t('superAdmin.auctionsResults'),
          href: '/activeAuctionResults',
          icon: TrendingUpIcon
        },
        {
          title: t('superAdmin.surveyResults'),
          href: '/surveyResults',
          icon: QuestionAnswerIcon
        },
        {
          title: t('superAdmin.histories'),
          href: '/suppliers/all/histories',
          icon: VisibilityIcon
        },
        {
          title: t('superAdmin.archive'),
          href: '/auctions/archive',
          icon: RestoreIcon
        },
        {
          title: t('superAdmin.requestReport'),
          href: '/requestReport',
          icon: ListAltIcon
        },
        {
          title: t('superAdmin.c2aActualResult'),
          href: '/actualCreditCardResults',
          icon: CreditCardIcon
        },
        {
          title: t('superAdmin.c2aInvoicesStatus'),
          href: '/c2aInvoicesStatus',
          icon: CardMembership
        },
        {
          title: t('superAdmin.buyersAdded'),
          href: '/buyersAdded',
          icon: GroupAddIcon
        },
        {
          title: t('superAdmin.prospect'),
          href: '/prospects',
          icon: StoreIcon
        },
        {
          title: t('superAdmin.deadlineDates'),
          href: '/allRoundDeadlines',
          icon: InsertInvitationIcon
        },
        {
          title: t('superAdmin.withRiba'),
          href: '/suppliers/withRiba',
          icon: AccountBalanceIcon
        },
        {
          title: t('superAdmin.pendingRegistrations'),
          href: '/suppliers/pendingRegistrations',
          icon: HourglassEmpty
        }
        // {
        //   title: t('superAdmin.templates'),
        //   href: '/emails/templates',
        //   icon: DashboardIcon
        // },title
        // {
        //   title: t('superAdmin.callcenters'),
        //   href: '/callcenters/combine',
        //   icon: VerifiedUserIcon
        // }
      ]
    }
  ]
  const paOperator = [
    {
      subheader: t('superAdmin.subheader'),
      items: [
        {
          title: t('superAdmin.userSection.title'),
          icon: HowToRegIcon,
          items: [{
            title: t('superAdmin.userSection.registered'),
            href: '/users'
          },
          {
            title: t('superAdmin.userSection.invited'),
            href: '/invitedusers'
          },
          {
            title: t('superAdmin.userSection.activations'),
            href: '/activations'
          }]
        },
        {
          title: t('superAdmin.companies'),
          href: '/companies',
          icon: BusinessIcon
        },
        {
          title: t('superAdmin.simulations'),
          href: '/auctions/simulations',
          icon: ExposureIcon
        },
        {
          title: t('superAdmin.auctions'),
          href: '/auctions',
          icon: SpeedIcon
        },
        {
          title: t('superAdmin.histories'),
          href: '/suppliers/all/histories',
          icon: VisibilityIcon
        },
        {
          title: t('superAdmin.auctionsResults'),
          href: '/activeAuctionResults',
          icon: TrendingUpIcon
        },
        {
          title: t('superAdmin.surveyResults'),
          href: '/surveyResults',
          icon: QuestionAnswerIcon
        },
        {
          title: t('superAdmin.archive'),
          href: '/auctions/archive',
          icon: RestoreIcon
        },
        {
          title: t('superAdmin.requestReport'),
          href: '/requestReport',
          icon: ListAltIcon
        },
        {
          title: t('superAdmin.buyersAdded'),
          href: '/buyersAdded',
          icon: GroupAddIcon
        },
        {
          title: t('superAdmin.c2aActualResult'),
          href: '/actualCreditCardResults',
          icon: CreditCardIcon
        },
        {
          title: t('superAdmin.c2aInvoicesStatus'),
          href: '/c2aInvoicesStatus',
          icon: CardMembership
        },
        {
          title: t('superAdmin.deadlineDates'),
          href: '/allRoundDeadlines',
          icon: InsertInvitationIcon
        },
        {
          title: t('superAdmin.withRiba'),
          href: '/suppliers/withRiba',
          icon: AccountBalanceIcon
        },
        {
          title: t('superAdmin.pendingRegistrations'),
          href: '/suppliers/pendingRegistrations',
          icon: HourglassEmpty
        }
      ]
    }
  ]
  const callcenter = {
    commercial: {
      subheader: t('callcenter.commercial.subheader'),
      items: [{
        title: t('callcenter.commercial.operators'),
        href: '/users',
        icon: HowToRegIcon
      },
      {
        title: t('callcenter.commercial.buyers'),
        href: '/buyers',
        icon: WorkIcon
      },
      {
        title: t('callcenter.commercial.report'),
        href: '/report',
        icon: ListAltIcon
      }]
    },
    operator: {
      subheader: t('callcenter.operator.subheader'),
      items: [{
        title: t('callcenter.operator.suppliers'),
        href: '/users',
        icon: HowToRegIcon
      }]
    }
  }
  const reverseDynamicSection = {
    subheader: t('reverseDynamic.subheader'),
    items: [{
      title: t('reverseDynamic.businessRelationship'),
      icon: BusinessCenterIcon,
      href: '/reverse-dynamic-discounting'
    }, {
      title: t('reverseDynamic.paymentsOperations'),
      icon: CreditCardIcon,
      href: '/reverse-dynamic-discounting/paymentRequests'
    }, {
      title: t('reverseDynamic.billing'),
      icon: ReceiptIcon,
      href: '/dynamic-request-to-pay/billing'
    }]
  }
  const crossBorderPaymentsSection = {
    title: t('crossBorder.title'),
    icon: Public,
    items: [{
      title: t('crossBorder.marketplaces'),
      href: '/cross-border/marketplaces'
    }, {
      title: t('crossBorder.handleSuppliers'),
      href: '/cross-border/suppliers'
    },
    {
      title: t('crossBorder.archive'),
      href: '/cross-border/archive'
    }]
  }
  const orderToCashSection = {
    subheader: t('creditCollection'),
    items: [{
      title: t('orderToCash.invoiceUpload'),
      icon: InsertDriveFileIcon,
      href: '/receivable-invoices/upload'
    },
    {
      title: t('orderToCash.invoiceUploadedArchive'),
      icon: HistoryIcon,
      href: '/receivable-invoices/archive'
    },
    {
      title: t('orderToCash.handleBuyers'),
      icon: PeopleIcon,
      href: '/receivable-invoices/handleBuyers'
    },
    {
      title: t('orderToCash.buyers'),
      icon: AssessmentIcon,
      href: '/receivable-invoices/buyers'
    },
    {
      title: t('orderToCash.receivedPayments'),
      icon: AccountBalanceIcon,
      href: '/receivable-invoices/incoming-payments'
    },
    {
      title: t('orderToCash.collectionServices'),
      icon: AttachMoneyIcon,
      href: '/integrations/collection-services'
    }
    ]
  }

  const supplier = {
    supplierAndBuyer: {
      subheader: t('supplier.supplierAndBuyer.subheader'),
      items: [{
        title: t('supplier.supplierAndBuyer.home'),
        icon: HomeIcon,
        href: '/suppliers/home'
      },
      {
        title: t('supplier.common.archive'),
        href: '/suppliers/archive',
        icon: AccountBalanceWalletIcon
      },
      {
        title: t('supplier.common.addBuyers'),
        icon: ShareIcon,
        href: '/addBuyers'
      }
      ]
    },
    common: {
      items: [{
        title: t('supplier.admin.home'),
        icon: HomeIcon,
        href: '/suppliers/home'
      }, {
        title: t('supplier.common.archive'),
        href: '/suppliers/archive',
        icon: AccountBalanceWalletIcon
      },
      {
        title: t('supplier.common.addBuyers'),
        icon: ShareIcon,
        href: '/addBuyers'
      }]
    },
    invitation: {
      items: []
    },
    admin: {
      items: [{
        title: t('supplier.admin.subheader'),
        icon: SettingsIcon,
        items: [{
          title: t('supplier.admin.userSection.title'),
          items: [{
            title: t('supplier.admin.userSection.registered'),
            href: '/users'
          },
          {
            title: t('supplier.admin.userSection.invited'),
            href: '/invitedusers'
          }]
        },
        {
          title: t('supplier.admin.notifications'),
          href: '/notifications'
        }
        ]
      }]
    },
    support: {
      subheader: t('supplier.support.subheader'),
      items: [
        {
          title: t('supplier.support.supportCenter'),
          icon: MenuBookIcon,
          href: '#',
          isExternalUrl: true
        },
        {
          title: t('supplier.support.contactSupport'),
          icon: HeadsetMicIcon,
          href: '/assistance'
        }
      ]
    },
    financial: {
      items: []
    },
    technical: {
      items: []
    }
  }
  const funderPartner = {
    admin: {
      items: [
        {
          title: t('funder.admin.home'),
          icon: HomeIcon,
          href: '/funders/home'
        },
        {
          title: t('funder.admin.subheader'),
          icon: SettingsIcon,
          items: [
            {
              title: t('buyer.admin.userSection.title'),
              items: [
                {
                  title: t('buyer.admin.userSection.registered'),
                  href: '/users'
                },
                {
                  title: t('buyer.admin.userSection.invited'),
                  href: '/invitedusers'
                }
              ]
            }
          ]
        }
      ]
    }
  }
  const buyer = {
    multiOptionBuyer: {
      items: [
        {
          title: t('buyer.multioption.home'),
          icon: HomeIcon,
          href: '/buyers/home'
        },
        {
          title: t('buyer.multioption.dynamicdiscounting.subheader'),
          icon: AccountBalanceWalletIcon,
          items: [
            //   {
            //   title: t('buyer.common.marketplace'),
            //   href: '/marketplace'
            // },
            {
              title: t('buyer.multioption.dynamicdiscounting.suppliers'),
              href: '/suppliers'
            },
            {
              title: t('buyer.multioption.dynamicdiscounting.archive'),
              href: '/buyers/archive'
            }]
        },
        {
          title: t('buyer.multioption.supplychainfinance.subheader'),
          icon: AccountBalanceIcon,
          href: '/scf/activePrograms'
          // items: [
          //   {
          //     title: t('buyer.multioption.supplychainfinance.activeprograms'),
          //     href: '/scf/activePrograms'
          //   },
          //   // {
          //   //   title: t('buyer.multioption.supplychainfinance.suppliers'),
          //   //   href: '/scf/handleSuppliers'
          //   // },
          //   {
          //     title: t('buyer.multioption.supplychainfinance.archive'),
          //     href: '/buyers/archive'
          //   }]
        },
        {
          title: t('buyer.multioption.creditcard.subheader'),
          icon: CreditCardIcon,
          href: '/scf/creditCardPrograms'
        },
        {
          title: t('buyer.multioption.supplychainfinance.suppliers'),
          icon: BusinessIcon,
          href: '/scf/handleSuppliers'
        },
        // {
        //   title: t('buyer.multioption.integrations.subheader'),
        //   icon: BuildIcon,
        //   href: '/integrations'
        // },
        {
          title: t('buyer.multioption.manualUpload.subheader'),
          icon: PublishIcon,
          href: '/invoicesUpload'
        },
        {
          title: t('buyer.multioption.manualApprove.subheader'),
          icon: AssignmentTurnedInIcon,
          href: '/invoicesApproval'
        }
      ]
    },
    buyerAndSupplier: {
      subheader: t('buyer.buyerAndSupplier.subheader'),
      items: [
        {
          title: t('buyer.buyerAndSupplier.home'),
          icon: HomeIcon,
          href: '/buyers/home'
        },
        {
          title: t('buyer.common.subheader'),
          icon: AccountBalanceWalletIcon,
          items: [
            //   {
            //   title: t('buyer.common.marketplace'),
            //   href: '/marketplace'
            // },
            {
              title: t('buyer.common.suppliers'),
              href: '/suppliers'
            },
            {
              title: t('buyer.common.archive'),
              href: '/buyers/archive'
            }]
        },
        {
          title: t('buyer.common.requestReport'),
          href: '/requestReport',
          icon: ListAltIcon
        }
      ]
    },
    common: {
      items: [
        {
          title: t('buyer.admin.home'),
          icon: HomeIcon,
          href: '/buyers/home'
        },
        {
          title: t('buyer.common.subheader'),
          icon: AccountBalanceWalletIcon,
          items: [
          //   {
          //   title: t('buyer.common.marketplace'),
          //   href: '/marketplace'
          // },
            {
              title: t('buyer.common.suppliers'),
              href: '/suppliers'
            },
            {
              title: t('buyer.common.archive'),
              href: '/buyers/archive'
            }]
        },
        {
          title: t('buyer.common.requestReport'),
          href: '/requestReport',
          icon: ListAltIcon
        }
      ]
    },
    admin: {
      items: [
        {
          title: t('buyer.admin.subheader'),
          icon: SettingsIcon,
          items: [
            {
              title: t('buyer.admin.userSection.title'),
              items: [
                {
                  title: t('buyer.admin.userSection.registered'),
                  href: '/users'
                },
                {
                  title: t('buyer.admin.userSection.invited'),
                  href: '/invitedusers'
                }
              ]
            },
            {
              title: t('buyer.financial.calendar'),
              href: '/calendar'
            }
          ]
        }
      ]
    },
    manager: {
      items: [
        {
          title: t('buyer.admin.subheader'),
          icon: SettingsIcon,
          items: [
            {
              title: t('buyer.admin.userSection.title'),
              items: [
                {
                  title: t('buyer.admin.userSection.registered'),
                  href: '/users'
                },
                {
                  title: t('buyer.admin.userSection.invited'),
                  href: '/invitedusers'
                }
              ]
            },
            {
              title: t('buyer.financial.calendar'),
              href: '/calendar'
            }
          ]
        }
      ]
    },

    financial: {
    },
    technical: {
      subheader: t('buyer.technical.subheader'),
      items: [{
        title: t('buyer.technical.integrations'),
        href: '/integrations',
        icon: SettingsInputHdmiIcon
      }]
    },
    support: {
      subheader: t('buyer.support.subheader'),
      items: [
        {
          title: t('buyer.support.supportCenter'),
          icon: MenuBookIcon,
          href: 'https://plusadvance.com/buyer-howto.pdf',
          isExternalUrl: true
        },
        {
          title: t('buyer.support.contactSupport'),
          icon: HeadsetMicIcon,
          href: 'mailto:supporto@plusadvance.com',
          isExternalUrl: true
        }
      ]
    }
  }
  const newConfiguration = {
    passiveCycle: {
      subheader: t('passiveCycle'),
      items: [
        {
          title: t('buyer.buyerAndSupplier.home'),
          icon: HomeIcon,
          href: '/buyers/home'
        }
      ]
    },
    orderToCashDebtor: {
      subheader: t('passiveCycle'),
      items: [
        {
          title: t('buyer.buyerAndSupplier.home'),
          icon: HomeIcon,
          href: '/buyers/dashboard'
        }
      ]
    },
    activeCycle: {
      subheader: t('activeCycle'),
      items: [
        {
          title: t('supplier.supplierAndBuyer.home'),
          icon: HomeIcon,
          href: '/suppliers/home'
        },
        {
          title: t('supplier.common.archive'),
          href: '/suppliers/archive',
          icon: AccountBalanceWalletIcon
        },
        {
          title: t('supplier.common.addBuyers'),
          icon: ShareIcon,
          href: '/addBuyers'
        }
      ]
    }
  }
  const ddItem = {
    title: t('buyer.multioption.dynamicdiscounting.subheader'),
    icon: AccountBalanceWalletIcon,
    items: [
      //   {
      //   title: t('buyer.common.marketplace'),
      //   href: '/marketplace'
      // },
      {
        title: t('buyer.multioption.dynamicdiscounting.suppliers'),
        href: '/suppliers'
      },
      {
        title: t('buyer.multioption.dynamicdiscounting.archive'),
        href: '/buyers/archive'
      }]
  }
  const creditCardItem = {
    title: t('buyer.multioption.creditcard.subheader'),
    icon: CreditCardIcon,
    href: '/scf/creditCardPrograms'
  }
  const orderToCashPaymentMethods = {
    title: t('buyer.multioption.paymentMethods.subheader'),
    icon: AccountBalanceIcon,
    href: '/order-to-cash/payments-methods'
  }
  const uploadInvoicesItem = {
    title: t('buyer.multioption.manualUpload.subheader'),
    icon: PublishIcon,
    href: '/invoicesUpload'
  }
  const approveInvoices = {
    title: t('buyer.multioption.manualApprove.subheader'),
    icon: AssignmentTurnedInIcon,
    href: '/invoicesApproval'
  }
  const yourSuppliersItem = {
    title: t('buyer.multioption.suppliers.subheader'),
    icon: Dashboard,
    href: '/buyers/suppliers/'
  }
  const evolvedPayByLink = {
    title: t('evolvedPayByLink.title'),
    icon: AccountBalanceIcon,
    items: [{
      title: t('reverseDynamic.businessRelationship'),
      icon: BusinessCenterIcon,
      href: '/reverse-dynamic-discounting'
    }, {
      title: t('reverseDynamic.paymentsOperations'),
      icon: CreditCardIcon,
      href: '/reverse-dynamic-discounting/paymentRequests'
    }, {
      title: t('reverseDynamic.billing'),
      icon: ReceiptIcon,
      href: '/dynamic-request-to-pay/billing'
    }]
  }
  const confirmingItem = {
    title: t('buyer.multioption.supplychainfinance.subheader'),
    icon: AccountBalanceIcon,
    href: '/scf/activePrograms'
  }
  const integrationsItems = {
    items: [{
      title: t('buyer.multioption.thirdParties.subheader'),
      icon: StoreIcon,
      items: [{
        title: t('buyer.multioption.thirdParties.services'),
        href: '/integrations/all'
      },
      {
        title: t('buyer.multioption.thirdParties.technicalServices'),
        href: '/integrations/technicalServices'
      }]
    }]
  }
  const handleSuppliers = {
    title: t('buyer.multioption.supplychainfinance.suppliers'),
    icon: BusinessIcon,
    href: '/scf/handleSuppliers'
  }
  return {
    yourSuppliersItem,
    handleSuppliers,
    integrationsItems,
    approveInvoices,
    confirmingItem,
    creditCardItem,
    ddItem,
    funderPartner,
    paOperator,
    superAdmin,
    buyer,
    supplier,
    callcenter,
    reverseDynamicSection,
    orderToCashSection,
    evolvedPayByLink,
    newConfiguration,
    uploadInvoicesItem,
    orderToCashPaymentMethods,
    crossBorderPaymentsSection
  }
}
